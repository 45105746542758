import React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'
import CommonFooter from '../components/footer/commonFooter'
import SiteNavigation from '../components/header/siteNavigation'
import Section16 from '../components/sections/section16'

const NotFoundPage = ({ data }) => {

  const { t } = useTranslation();

  return (
    <>
      <SiteNavigation />
        <section className="hero is-large is-gradient">
          <div className="hero-body">
            <div className="container has-text-centered">
              <h1 className="title is-1 is-white">
                {t('404.message')}
              </h1>
              <Section16
                gradient=""
                textColor="is-white"
                // sectionTitle={t('about.discoverTitle')}
                // sectionDesc={t('about.discoverDesc')}
                features={[1, 2, 3, 4, 5, 6]}
                featureTitle={[
                  <>{t('about.feature1')}</>,
                  <>{t('about.feature2')}</>,
                  <>{t('about.feature3')}</>,
                  <>{t('about.feature4')}</>,
                  <>{t('about.feature5')}</>,
                  <>{t('about.feature6')}</>,
                ]}
                featureDesc={[
                  <>{t('about.feature1desc')}</>,
                  <>{t('about.feature2desc')}</>,
                  <>{t('about.feature3desc')}</>,
                  <>{t('about.feature4desc')}</>,
                  <>{t('about.feature5desc')}</>,
                  <>{t('about.feature6desc')}</>,
                ]}
                featureButton={[
                  <>{t('about.featureButton')}</>,
                  <>{t('about.featureButton')}</>,
                  <>{t('about.featureButton')}</>,
                  <>{t('about.featureButton')}</>,
                  <>{t('about.featureButton')}</>,
                  <>{t('about.featureButton')}</>,
                ]}
                featureButtonLink={[
                  '/prodotti/hera-laundry',
                  '/prodotti/fiber-el6',
                  '/prodotti/schede-rele-interfaccia-plc',
                  '/prodotti/sonde-di-temperatura',
                  '/servizi/progettazione-elettronica',
                  '/servizi/produzione-schede-elettroniche',
                ]}
                imageCss={{ top: 0, left: 0, right: 0, bottom: 0 }}
                imageStyle={{
                  position: `relative`,
                  width: '180px',
                  height: '180px',
                }}
                imageFixed={[
                  data.hera.childImageSharp.fixed,
                  data.el6.childImageSharp.fixed,
                  data.schedapotenza.childImageSharp.fixed,
                  data.sensoristica.childImageSharp.fixed,
                  data.progettazioneElettronica.childImageSharp.fixed,
                  data.contractElectronicsManufacturing.childImageSharp.fixed,
                ]}
                alt={[
                  t('about.altHeraIcon'),
                  t('about.altEL6Icon'),
                  t('about.altSchedaPotenzaIcon'),
                  t('about.altSensoriIcon'),
                  t('about.altProgettazioneIcon'),
                  t('about.altProduzioneIcon'),
                ]}
              />
            </div>
          </div>
        </section>
      <CommonFooter />
    </>
  )
}

export default NotFoundPage

export const notFound = graphql`
  query {
    sensoristica: file(relativePath: { eq: "icons/Sensoristica.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    schedapotenza: file(relativePath: { eq: "icons/SchedaIO.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    hera: file(relativePath: { eq: "icons/HERA.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    el6: file(relativePath: { eq: "icons/EL6.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    sviluppoSoftware: file(relativePath: { eq: "icons/SviluppoSoftware.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    progettazioneElettronica: file(
      relativePath: { eq: "icons/ProgettazioneElettronica.png" }
    ) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    contractElectronicsManufacturing: file(
      relativePath: { eq: "icons/ContractElectronicsManufacturing.png" }
    ) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

